<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #833133">แก้ไขผู้ใช้งาน</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ชื่อ - นามสกุล</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="name"
              label="ชื่อ - นามสกุล"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ประเภท</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-select
              v-model="userType"
              :items="userTypeItems"
              placeholder="เลือกประเภท"
              dense
              solo
              :rules="rules.required"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ตำแหน่ง</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="position"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">รหัสวิทยุ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field v-model="radioCode" solo dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">หมายเลขโทรศัพท์</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="tel"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="9" sm="9" xs="9">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="10"
                  md="10"
                  sm="10"
                  xs="10"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปโปรไฟล์</span>
                  <span style="color: red">*</span>
                  <v-img :src="showImage" width="200px"></v-img>
                  <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                    :rules="rules.pic"
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /></v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" justify="end" class="mt-4">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      profileImg: "",
      name: "",
      password: "",
      position: "",
      radioCode: "",
      tel: "",
      userType: "",
      userTypeItems: [
        { text: "ประชาชน", value: "USER" },
        { text: "สายตรวจ", value: "POLICE_1" },
        { text: "ตำรวจจราจร", value: "POLICE_2" },
      ],
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      //   datefrom: new Date().toISOString().substr(0, 10),
      //   dateto: new Date().toISOString().substr(0, 10),
      //   menufrom: false,
      //   menuto: false,
      //   firstDate: new Date("2021-06-01").toISOString().slice(0, 10),
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    this.id = userdata.id;
    this.showImage = userdata.profileImg;
    this.name = userdata.name;
    // this.password = userdata.password;
    this.position = userdata.position;
    this.radioCode = userdata.radioCode;
    this.tel = userdata.tel;
    this.userType = userdata.userType;
    this.activeFlag = userdata.activeFlag;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageUser");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        // var user = JSON.parse(
        //   Decode.decode(localStorage.getItem("userPoontana"))
        // );
        // const auth = {
        //   headers: { Authorization: `Bearer ${user.token}` },
        // };
        const data = {
          // discountType: this.discountType,
          profileImg: this.profileImg,
          name: this.name,
          //   password: this.password,
          position: this.position,
          radioCode: this.radioCode,
          tel: this.tel,
          userType: this.userType,
          profileImg: this.pic1,
          activeFlag: this.activeFlag,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/users/` + this.id,
          data
          //   ,auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขผู้ใช้งานสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageUser");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขผู้ใช้งานไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>